import { GET, POST } from '@/utils/axios'

export const getBooking = async ({ bookingId = '', slotId = '', email = '' }) =>
  await GET(
    `/booking/${bookingId}?slotId=${slotId}&email=${encodeURIComponent(email)}`
  )

export const getSlot = async ({ bookingId = '', slotId = '' }) =>
  await GET(`/booking/${bookingId}/slot/${slotId}`)

export const selectSlot = async ({
  bookingId = '',
  slotId = '',
  email = '',
  uniqueCode = ''
}) => {
  return await POST(`/booking/${bookingId}/slot/${slotId}`, {
    bookingId,
    slotId,
    email,
    uniqueCode
  })
}

export const cancelSlot = async ({
  bookingId = '',
  slotId = '',
  email = '',
  uniqueCode = ''
}) => {
  return await POST(`/cancel-booking/${bookingId}/slot/${slotId}`, {
    email,
    uniqueCode
  })
}
